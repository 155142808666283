.client-image {
    display: block;
    width: 300px;
    height: auto !important;
}

.clients {
    background-color: var(--main);
}

.seperator {
    width: 100%;
    padding-left: 170px;
    padding-right: 170px;
}

.header-link {
    text-decoration: none !important;
    padding: 2rem;
}

.nasser-text-gif {
    height: 180px;
}

.nasser-avatar-gif {
    height: 260px;
}

.gallery-image {
    max-width: 100%;
    padding: 5px;
}

.grey-bg {
    background-color: rgb(238, 238, 238);
}

.navigator-image-container {
    height: 260px;
}
.navigator-image {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0;
    display: block;
    height: 230px;
    width: auto !important;
}

.navigator-image:hover {
    height: 260px
}

.series-image-container {
    height: 300px;
}

.series-image {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0;
    padding-bottom: 1rem;
    display: block;
    height: 260px !important;
}

.series-image:hover {
    cursor: pointer;
    height: 275px !important;
}

.footer {
    bottom: 0;
    background-color: var(--main);
    max-width: 100%;
}

.work-together {
    width: 80%;
    padding: 30px;
}

.inactive-comics {
    color: rgb(146, 146, 146);
}

.animation-container {
    position: relative;
}

.space-image {
    height: 450px;
    animation: rotating 25s linear infinite;
}

.social-media {
    height: 60px;
    margin: 20px;
} 

.see-more {
    margin: 10px;
    border-radius: 30px !important;
}

.card-container {
    width: 70%;
    cursor: pointer;
    background-color:var(--main) !important;
    margin-bottom: 50px;
}

.series-container {
    margin-top: 50px;
    min-height: 500px;
}

.series-title {
    max-width: 100%;
    height: auto;
    max-height: 200px;
    padding: 10px;
}

.card-main-text {
    letter-spacing: 2px;
    font-weight: bolder;
    font-size: 25px !important;
}

.card-sub-text {
    letter-spacing: 2px;
    font-size: 20px !important;
}

.cards-section {
    margin-top: 50px !important;
    margin-bottom: 30px;
}

.about-image {
    width: 80%;
    height: fit-content;
}

.about-section {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.about-text {
    font-size: 25px;
    letter-spacing: 0.5;
    white-space: pre-wrap;
}

.header-text {
    letter-spacing: 2px !important;
    font-size: 30px;
  }
  
.intro {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.active-link {
    color: #212529;
    color: var(--main) !important;
}

.remove-decoration {
    text-decoration: none !important;
}

.images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.column-container {
    flex-grow: 1;
    width: 30%;
}

.comic-image {
    width: 100%;
    margin-bottom: 30px;
    cursor: pointer;
}

.intro-text {
    font-size: 30px;
    line-height: 40px;
}

.follow-image {
    max-width: 100%;
}

.panel-image {
    width: 100%;
    height: auto;
}

@keyframes rotating {
    0% { transform: rotate(0); }
    100%   { transform: rotate(360deg); }    
}

@media only screen and (max-width: 600px) {
    .nasser-text-gif {
        height: 100px;
    }
    
    .nasser-avatar-gif {
        height: 120px;
    }

    .header-text {
        font-size: 18px;
        font-size: bold;
    }

    .header-link {
        padding: 10px;
    }

    .column-container {
        flex-grow: 1;
        width: 100%;
    }

    .intro-text {
        font-size: 20px;
        line-height: 20px;
    }

    .space-image {
        height: 250px;
    }

    .email-text {
        font-size: 20px;
    }

    .social-media {
        height: 30px;
    }
  }